
import { importSync as i, macroCondition, getGlobalConfig } from '@embroider/macros';
let w = window;
let d = w.define;


i("./-embroider-implicit-modules.js");

d("ember-performance/app", function(){ return i("ember-performance/app.js");});
d("ember-performance/config/environment", function(){ return i("ember-performance/config/environment.js");});
d("ember-performance/router", function(){ return i("ember-performance/router.js");});
d("ember-performance/services/qps/array", function(){ return i("ember-performance/services/qps/array.ts");});
d("ember-performance/services/qps/base", function(){ return i("ember-performance/services/qps/base.ts");});
d("ember-performance/services/qps/boolean", function(){ return i("ember-performance/services/qps/boolean.ts");});
d("ember-performance/services/qps/number", function(){ return i("ember-performance/services/qps/number.ts");});
d("ember-performance/services/qps/types", function(){ return i("ember-performance/services/qps/types.ts");});
d("ember-performance/services/query-params", function(){ return i("ember-performance/services/query-params.ts");});
d("ember-performance/services/page-title", function(){ return i("ember-performance/services/page-title.js");});
d("ember-performance/services/bench-session", function(){ return i("ember-performance/services/bench-session.js");});
d("ember-performance/services/runner/for-app-at-version", function(){ return i("ember-performance/services/runner/for-app-at-version.js");});
d("ember-performance/services/ember-primitives/setup", function(){ return i("ember-performance/services/ember-primitives/setup.js");});
d("ember-performance/component-managers/glimmer", function(){ return i("ember-performance/component-managers/glimmer.js");});
d("ember-performance/initializers/app-version", function(){ return i("ember-performance/initializers/app-version.js");});
d("ember-performance/container-debug-adapter", function(){ return i("ember-performance/container-debug-adapter.js");});
d("ember-performance/services/-ensure-registered", function(){ return i("ember-performance/services/-ensure-registered.js");});
d("ember-performance/templates/application", function(){ return i("ember-performance/templates/application.js");});
d("ember-performance/routes/application", function(){ return i("ember-performance/routes/application.ts");});
d("ember-performance/templates/bench", function(){ return i("ember-performance/templates/bench.js");});
d("ember-performance/routes/bench", function(){ return i("ember-performance/routes/bench.js");});
d("ember-performance/templates/index", function(){ return i("ember-performance/templates/index.js");});
d("ember-performance/routes/index", function(){ return i("ember-performance/routes/index.ts");});
d("ember-performance/templates/report", function(){ return i("ember-performance/templates/report.ts");});
d("ember-performance/templates/utils", function(){ return i("ember-performance/templates/utils.ts");});
d("ember-performance/templates/versions", function(){ return i("ember-performance/templates/versions.js");});





if (!runningTests) {
  i("../app").default.create({"name":"ember-performance","version":"0.13.0+cc483a7e"});
}

